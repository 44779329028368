.chart-container {
    max-width: 1500px;
    margin: 50px auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 25px auto;
}

.form-group {
    display: flex;
    align-items: center;
}
.form-element {
    margin: 8px;
    width: 250px;
    height: 30px;
    font-size: 16px;
}
.form-label {
    display: flex;
    margin: 8px;
    width: 30px;
    height: 30px;
    align-items: center;
}
.button {
    background-color: #e7e7e7;
    border: none;
    color: black;
    padding: 0 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 8px;
    height: 36px;
    cursor: pointer;
}
.actual-data {
    font-size: 20px;
    margin: 8px;
}

.border-dotted {
    border: 1px gray dashed;
}